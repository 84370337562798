<template>
	<standard-container title="log" description="" admin>
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab key="tabLog">
					Log
				</v-tab>
				<v-tab key="tabDatabase">
					Database
				</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-tabs-items v-model="currentTab">
					<!-- tabLog -->
					<v-tab-item key="tabLog">
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<div class="d-block d-md-flex align-baseline">
									<v-text-field ref="searchField" v-model="search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus prepend-inner-icon="mdi-magnify" label="Ricerca" clear-icon="mdi-close-circle" clearable hint="Ricerca con like. Mettere tra virgolette per ricerca diretta. Premettere '-' per escludere." @keyup.enter="refreshList" @click:clear="refreshList" />
									<v-select v-model="logLevel" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Filtra per livello" :items="logLevels" class="ml-0 ml-md-10" style="max-width:100px" @change="refreshList" />
									<v-select v-model="logLimit" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Numero di record" :items="itemsPerPageOptions" class="ml-0 ml-md-10" style="max-width:100px" @change="refreshList" />
								</div>
							</v-col>
						</v-row>
						<v-data-table 
							dense
							class="mt-6"
							:loading="loading"
							:items="logs" 
							:headers="tableHeaders"
							:sort-by="['DataCreazione']" 
							:sort-desc="[true]"
							item-key="Id"
							hide-default-footer
							disable-pagination
							disable-filtering
							show-expand
							:single-expand="false"
						>
							<template v-slot:item.TimeStamp="{ item }">
								<div class="d-flex">
									<div class="mr-1" style="width:2px;min-width:2px" :style="item.IsToday ? 'background-color:#f70' : (item.IsYesterday ? 'background-color:#a30' : '')" />
									<standard-label>{{ item.TimeStamp | shortData }}</standard-label>
									<span style="white-space:pre"> - </span>
									<standard-label>{{ item.TimeStamp | hour }}</standard-label>
								</div>
							</template>
							<template v-slot:item.Level="{ item }">
								<v-chip v-if="item.Level==='Debug'" x-small tile label color="">
									{{ item.Level }}
								</v-chip>
								<v-chip v-else-if="item.Level==='Information'" x-small tile label color="info">
									{{ item.Level }}
								</v-chip>
								<v-chip v-else-if="item.Level==='Warning'" x-small tile label color="warning">
									{{ item.Level }}
								</v-chip>
								<v-chip v-else-if="item.Level==='Error'" x-small tile label color="error">
									{{ item.Level }}
								</v-chip>
								<v-chip v-else-if="item.Level==='Fatal'" small label color="error">
									<v-icon left>
										mdi-alert-circle-outline
									</v-icon>{{ item.Level }}
								</v-chip>
							</template>
							<template v-slot:item.Message="{ item }">
								<div style="position: relative;">
									<div style="position: absolute;width:100%; height:20px; margin-top: -10px; white-space: nowrap;overflow: hidden;">
										{{ item.Message }}
									</div>
								</div>
							</template>
							<template v-slot:item.Exception="{ item }">
								<v-icon v-if="item.Exception" x-small>
									mdi-alert
								</v-icon>
							</template>
							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length">
									<div class="expanded">
										<textarea class="monospaced" readonly :value="item.Message" rows="10" />
										<v-alert v-if="item.Exception && item.Level === 'Fatal'" type="error" prominent tile text class="alert">
											<textarea :value="item.Exception" rows="10" />
										</v-alert>
										<v-alert v-if="item.Exception && item.Level === 'Error'" type="error" dense tile text class="alert">
											<textarea :value="item.Exception" rows="10" />
										</v-alert>
										<v-alert v-if="item.Exception && item.Level === 'Warning'" type="warning" dense tile text class="alert">
											<textarea :value="item.Exception" rows="10" />
										</v-alert>
										<table class="detailTable mt-0 mb-1">
											<tbody>
												<tr>
													<td class="caption">
														User
													</td><td class="caption" style="width:100%">
														{{ item.UserName }} / {{ item.UserType }}
													</td>
												</tr>
												<tr>
													<td class="caption">
														IP
													</td><td class="caption" style="width:100%">
														{{ item.UserIP }}
													</td>
												</tr>
												<tr>
													<td class="caption">
														Api
													</td><td class="caption" style="width:100%">
														{{ item.WebApiRouteTemplate }}
													</td>
												</tr>
												<tr>
													<td class="caption">
														UserAgent
													</td><td class="caption" style="width:100%">
														{{ item.HttpRequestUserAgent }}
													</td>
												</tr>
												<tr>
													<td class="caption">
														SessionId
													</td><td class="caption" style="width:100%">
														{{ item.HttpSessionId }}
													</td>
												</tr>											
											</tbody>
										</table>
									</div>
								</td>
							</template>
						</v-data-table>
					</v-tab-item>
					<!-- tabDatabase -->
					<v-tab-item key="tabDatabase">
						<v-alert prominent text border="left" type="info">
							Questa funzione è ancora in fase di sviluppo.
						</v-alert>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
		
		<!-- footer -->
		<template v-slot:footer>
			<v-row class="mt-1">
				<v-col cols="6" sm="2" order="2" order-sm="1">
					<v-btn small class="mt-3" icon title="Ricarica la lista" :disabled="loading" :loading="loading" @click="refreshList">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</template>

		<loading :loading="loading" />
		<v-snackbar v-model="errorShow" top :timeout="4000" color="error">
			{{ errorText }}
		</v-snackbar>
	</standard-container>
</template>

<script>
import StandardContainer from '@/components/standard-container'
// import DebugContainer from '@/components/debug-container'
// import DebugPanel from '@/components/debug-panel'
import StandardLabel from '@/components/standard-label'
import Loading from '@/components/loading'
import moment from 'moment'
export default {
	name: 'AdminLogPage',
	components: { StandardContainer, StandardLabel, Loading },
	filters: {
		shortData: function (date) {
			return moment(date).format('YYYY-MM-DD')
		},
		hour: function (date) {
			return moment(date).format('HH:mm:ss')
		},
		jsonFormat: function(json) {
			return JSON.stringify(JSON.parse(json), null, 2) 
		}
	},
	data: () => ({
		currentTab:null,
		loading: false,
		errorShow: false,
		errorText: '',
		search: '',
		expanded: [],
		logLevel: 'all',
		logLimit: 100,
		logLevels: ['all', 'debug', 'information', 'warning', 'error', 'fatal' ],
		logs: [],
		currentLog: {},
		itemsPerPageOptions: [{text:'100', value:100},{text:'200', value:200},{text:'500', value:500},{text:'1000', value:1000}],	
		tableHeaders: [
			{ text: '', value: 'data-table-expand' },
			{ text: 'Timestamp', value: 'TimeStamp'},
			{ text: 'Level', value: 'Level'},
			{ text: 'User', value: 'UserName'},
			{ text: 'Message', value: 'Message', width:'100%' },
			{ text: '', value: 'Exception'},
		],
	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
	},
	watch:{
		// eslint-disable-next-line no-unused-vars
		$route (to, from){
			this.$refs.searchField.focus()
		}
	},
	mounted() {
		this.fetchData(this.search, this.logLevel, this.logLimit)
	},
	methods: {
		fetchData(search, level, limit) {
			this.loading = true
			this.errorShow = false
			this.$store.dispatch('genericApiPost', {apiUrl: '/setup/log/', value: {Filter: search, Level: level, Limit: limit} }).then(data => {
				this.logs = data.LastLogs
				this.loading = false
			}).catch(error => {
				console.error('LOG', error)
				this.loading = false
				this.errorText = error
				this.errorShow = true
			})
		},
		refreshList() {
			this.fetchData(this.search, this.logLevel, this.logLimit)
		}
	}
}
</script>

<style scoped lang="less">
.expanded {
	background-color: #333;
	padding: 2px;
	padding-left: 5px;
	margin-left: -15px;
	margin-right: -15px;
    margin-top: -1px;
	font-family: monospace;
	font-size: 12px !important;
	line-height: 14px !important;
	.alert {
		white-space: pre-wrap;
		overflow-y: auto !important;
		overflow-x: hidden !important;
		textarea {
			font-size: 12px !important;
			line-height: 14px !important;
			width: 100% !important;
			background-color: transparent;
			color: white;
		}
	}
	.monospaced {
		margin-top: 0 !important;
		padding-top: 0 !important;
		overflow-y: auto !important;
		overflow-x: hidden !important;
		color: greenyellow !important;
		margin-bottom: 0 !important;
	}
	textarea.monospaced {
		width: 100% !important;
		border-bottom: dashed 1px greenyellow;
	}
}
.detailTable {
	width: auto;
	tr:hover {
		background: transparent !important;
	}
	td {
		border-bottom:none !important;
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 0;
		height: auto;
		line-height: 14px !important;
		color:greenyellow;
		&:first-child {
			font-weight: bold;
		}

	}

}

</style>